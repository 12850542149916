import React from 'react';
import {Navigate, Route} from 'react-router-dom';
import {apiDocsPlugin, ApiExplorerPage} from '@backstage/plugin-api-docs';
import {CatalogEntityPage, CatalogIndexPage, catalogPlugin,} from '@backstage/plugin-catalog';
import {ScaffolderPage, scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {orgPlugin} from '@backstage/plugin-org';
import {SearchPage} from '@backstage/plugin-search';
import {TechRadarPage} from '@backstage/plugin-tech-radar';
import {TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage,} from '@backstage/plugin-techdocs';
import {TechDocsAddons} from '@backstage/plugin-techdocs-react';
import {ReportIssue} from '@backstage/plugin-techdocs-module-addons-contrib';
import {UserSettingsPage} from '@backstage/plugin-user-settings';
import {apis} from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {searchPage} from './components/search/SearchPage';
import {Root} from './components/Root';

import {AlertDisplay, OAuthRequestDialog, SignInPage} from '@backstage/core-components';
import {createApp} from '@backstage/app-defaults';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {CatalogGraphPage} from '@backstage/plugin-catalog-graph';

import {gitlabAuthApiRef, microsoftAuthApiRef} from '@backstage/core-plugin-api';
import {ITSOAdminPage} from '@techx/plugin-mate-jira';
import {BJBTheme} from "./BJBTheme";
import {UnifiedThemeProvider} from "@backstage/theme";
import {Mermaid} from 'backstage-plugin-techdocs-addon-mermaid';
import {MERMAID_CONFIG} from "./config/mermaid";

const app = createApp({
  themes: [{
    id: 'jb-theme',
    title: 'BJB',
    variant: 'dark',
    Provider: ({children}) => (
      <UnifiedThemeProvider theme={BJBTheme} children={children}/>
    ),
  }],
  apis,
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        providers={
          [
            {
              id: 'microsoft-auth-provider',
              title: 'Microsoft',
              message: 'Sign in using Microsoft',
              apiRef: microsoftAuthApiRef,
            },
            {
              id: 'gitlab-auth-provider',
              title: 'GitLab',
              message: 'Sign in using GitLab',
              apiRef: gitlabAuthApiRef,
            }
          ]}
      />
    ),
  },
  bindRoutes({bind}) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: undefined,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: undefined,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: undefined,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="tech-radar"/>}/>
    <Route path="/catalog" element={<CatalogIndexPage/>}/>
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage/>}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage/>}/>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage/>}
    >
      <TechDocsAddons>
        <ReportIssue/>
        <Mermaid config={MERMAID_CONFIG}/>
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage/>}/>
    <Route path="/api-docs" element={<ApiExplorerPage/>}/>
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800}/>}
    />
    <Route path="/search" element={<SearchPage/>}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage/>}/>
    <Route path="/catalog-graph" element={<CatalogGraphPage/>}/>
    <Route path="/itsoadmin" element={<ITSOAdminPage/>}/>
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay/>
    <OAuthRequestDialog/>
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
