import {
  RadarEntry,
  RadarEntrySnapshot,
  RadarQuadrant,
  RadarRing,
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';

import entriesQ2_23 from './editions/Q2-2023';
import entriesQ3_23 from './editions/Q3-2023';
import entriesQ1_25 from './editions/Q1-2025';
import quadrants from './quadrants.json';
import rings from './rings.json';

export default class TechRadarClient implements TechRadarApi {
  async load() {
    const radarEntriesQ2_23 = entriesQ2_23.map(createEntryFromJson);
    const radarEntriesQ3_23 = entriesQ3_23.map(createEntryFromJson);
    const radarEntriesQ1_25 = entriesQ1_25.map(createEntryFromJson);

    const allRadarEntries: RadarEntry[] = [
      ...radarEntriesQ1_25,
      ...radarEntriesQ3_23.filter(notIncludedIn(radarEntriesQ1_25)),
      ...radarEntriesQ2_23.filter(notIncludedIn(radarEntriesQ3_23))
    ];

    const response: TechRadarLoaderResponse = {
      entries: allRadarEntries,
      quadrants: quadrants as RadarQuadrant[],
      rings: rings as RadarRing[],
    };
    return response;
  }
}

function createEntryFromJson(entry: any): RadarEntry {
  return {
    ...entry,
    timeline: entry.timeline.map(createDateFromString),
  };
}

function createDateFromString(snapshot: any): RadarEntrySnapshot {
  return {
    ...snapshot,
    date: new Date(snapshot.date),
  };
}

function notIncludedIn(radarEntries: RadarEntry[]) {
  const allIDs = new Set(radarEntries.map(e => e.id as string));
  return function(entry: RadarEntry): boolean {
    return !allIDs.has(entry.id);
  }
}
